import { Component, Host, h } from '@stencil/core';

@Component({
  tag: 'x-layout-office',
  styleUrl: 'x-layout-office.sass',
})
export class XLayoutOffice {
  render() {
    return (
      <Host class="x-layout-office">
        <div class="x-layout-office__header">
          <slot name="layout-header"></slot>
        </div>
        <div class="x-layout-office__body">
          <div class="x-layout-office__aside">
            <slot name="layout-aside"></slot>
          </div>
          <div class="x-layout-office__main">
            <slot name="layout-main"></slot>
          </div>
        </div>
      </Host>
    );
  }
}
