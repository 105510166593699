.x-layout-office
  display: flex
  flex-direction: column
  height: 100%
  overflow: hidden
  &__header
    flex: 0 0 auto
  &__body
    flex: 1 1 auto
    display: flex
    height: 0
  &__aside
    flex: 0 0 auto
    height: 100%
  &__main
    flex: 1 1 auto
    overflow: auto
    padding: 32px
